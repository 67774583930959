import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext
} from "react";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { useLocation } from "react-router-dom";
import { SINGLE_TAP_LINK } from "utils/constants";
import { ModalContext } from "components/Modal/ModalContext";
import atmosfyLogo from "images/atmosfy-logo.svg";
import "./WatchFeedModal.css";


// TODO: Refactor Routes and layouts to remove this check
const NO_OPEN_ON_LOAD_FOR = [
  "/scholarship",
  "/kygo-sweepstakes",
  "/kygo-sweepstakes-rules",
  "/creators",
  "/communityround"
];

const titleStyles = {
  fontFamily: "proxima-nova",
  fontSize: "19px",
  fontWeight: "600",
  textAlign: "center",
  marginTop: "0",
  marginBottom: "0"
};

const descriptionStyles = {
  fontFamily: "proxima-nova",
  fontSize: "16px",
  fontWeight: "400",
  textAlign: "center",
  marginTop: "8px",
  marginBottom: "24px"
};

const WatchFeedModal = (props) => {
  const { isActive, openModal, closeModal } = useContext(ModalContext);
  const { pathname } = useLocation();

  let modalClass = isActive ? "modal active" : "modal";

  useEffect(() => {
    if (NO_OPEN_ON_LOAD_FOR.includes(pathname)) {
      return;
    }
    openModal();
  }, [openModal, pathname]);

  const handleClickCta = useCallback((ev) => {
    ReactGA.event({
      category: "Modal",
      action: "Tapped Open Atmosfy in Modal"
    });
    ReactPixel.trackCustom("MW_WATCH_Modal_Open_Atmosfy");

    window.open(SINGLE_TAP_LINK);
  }, []);

  const handleClickNotNow = useCallback(
    (ev) => {
      ev.stopPropagation();
      ReactGA.event({
        category: "Modal",
        action: "Tapped Not Now in Modal"
      });
      ReactPixel.trackCustom("MW_WATCH_Modal_Not_Now");

      closeModal();
    },
    [closeModal]
  );

  return (
    <div className={modalClass}>
      <div className="modal__background" />
      <div className="modal__content">
        <div className={"modal__logo"}>
          <img src={atmosfyLogo} alt="Atmosfy logo" loading="lazy" />
        </div>

        <pre style={titleStyles}>
          Get the full app experience
        </pre>
        <pre style={descriptionStyles} className="modal__description">
          {"Enjoy more videos and great features on the\nAtmosfy app."}
        </pre>

        <button onClick={handleClickCta} className="button__cta button__label">
          Install Now
        </button>

        <button
          className="button__label-tight button__not-now"
          onClick={handleClickNotNow}
        >
          Not Now
        </button>
      </div>
    </div>
  );
};

export default WatchFeedModal;
