import React, { createContext, useContext, useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import * as API from "utils/api";

const LocationProfileContext = createContext(undefined);

export const useLocationProfile = () => {
  const context = useContext(LocationProfileContext);
  if (context === undefined) {
    throw new Error(
      "useLocationProfile must be used within a LocationProfileProvider"
    );
  }
  return context;
};

const LocationProfileProvider = ({ children }) => {
  const routeParams = useParams();

  const [locationData, setLocationData] = useState(null);
  const [feedData, setFeedData] = useState(null);
  const [defaultVideo, setDefaultVideo] = useState(null);

  useEffect(() => {
    const { locationIdentifier, reviewId } = routeParams || {};
    if (!locationIdentifier && !reviewId) {
      return;
    }

    const getLocationData = async () => {
      const { metaResponse, videos } = locationIdentifier
        ? await API.fetchLocationProfile(locationIdentifier, reviewId)
        : await API.fetchLocationProfileByReviewId(reviewId);

      setLocationData(metaResponse);
      setFeedData(videos);
    };

    const getProductDefaultVideo = async () => {
      const video = await API.fetchProductDefaultVideo();
      setDefaultVideo({
        ...video,
        isDefault: true
      });
    }

    getLocationData().catch((error) => console.log({ error }));
    getProductDefaultVideo().catch((error) => console.log({ error }));
  }, [routeParams.locationIdentifier, routeParams.reviewId]);

  const context = useMemo(() => {
    return {
      locationData,
      feedData,
      defaultVideo
    };
  }, [feedData, locationData, defaultVideo]);

  return (
    <LocationProfileContext.Provider value={context}>
      {children}
    </LocationProfileContext.Provider>
  );
};

export default LocationProfileProvider;
