import React from "react";
import useTrackEvent from "hooks/useTrackEvent";
import { EVENTS } from "utils/constants/events";
import { ReactComponent as ArrowRight } from "assets/fillableIcons/arrow-back.svg";
import styles from "./ClaimBusinessBanner.module.css";

const ClaimBusinessBanner = () => {
  const trackEvent = useTrackEvent();

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <span className={styles.text}>
          Get Videos From Top Local Creators For Your Business Today
        </span>
        <a
          onClick={() => trackEvent(EVENTS.WEB_Claim_Business_Link)}
          href="/"
          target="_self"
          rel="noreferrer">
          <button className={styles.claimBusinessButton}>
            <span className={styles.linkText}>Claim Your Business</span>
            <ArrowRight width="20" height="20" fill='#04060C' />
          </button>
        </a>
      </div>
    </div>
  )
}

export default ClaimBusinessBanner;
